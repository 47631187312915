<template>
  <div class="w-full">
    <div style="margin-bottom: 16px">
      <CompaniesTableHeader :showButton="hasSelected" @toggle-modal="toggleModal" @search-term="onSearchTerm"  :search="search"/>
    </div>
     <div v-if="!loading">
    <div class="w-full overflow-x-auto rounded-xl border border-gray-300 bg-white companies">
        <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        >
        <div slot="name" slot-scope="text,record">{{record.name | capitalize}}</div>
        <div slot="company_type_name" slot-scope="text,record">{{record.company_type_name | capitalize}}</div>
        <div slot="nit" slot-scope="text,record" class="text-center">{{record.nit}}</div>
        <div slot="sfc_code" slot-scope="text,record" class="text-center">{{record.sfc_code}}</div>
          <span slot="actions" slot-scope="text, record" class=" text-sm font-medium text-right whitespace-nowrap flex">
            <a-switch v-model="record.is_active" @change="onSwitchChange($event, record)" />
            <router-link :to="`./edit/?id=${record.id}`" class="ml-3 h-6 w-6 p-1 text-blue-600 dark:text-blue-500 shadow rounded" append>
                <img class="h-full w-full" src="@/assets/icons/pen.svg" alt="">
            </router-link>
          </span>
        </a-table>
    </div>
    <div class="mt-4 flex justify-end">
        <a-pagination 
        v-model="page" 
        :page-size="pageSize"
        :page-size-options="pageSizeOptions"
        :total="count"  
        size="large" 
        show-size-changer
        @showSizeChange="onShowSizeChange"/>
    </div>
     </div>
      <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0 ">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <ModalConfirmation :visible="showModal" :companies="selectedRowKeys" @toggle-modal="toggleModal" :data="data" />
  </div>
</template>
<script>
import CompaniesTableHeader from '@/components/Dashboard/Companies/CompaniesTableHeader'
import ModalConfirmation from './ModalConfirmation.vue'
const columns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    width:300,
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'NIT',
    dataIndex: 'nit',
    key: 'nit',
    width:200,
    scopedSlots: { customRender: 'nit' },
  },
  {
    title: 'Código',
    dataIndex: 'sfc_code',
    key: 'sfc_code',
    width:100,
    scopedSlots: { customRender: 'sfc_code' },
  },
  {
    title: 'Tipo de entidad',
    dataIndex: 'company_type_name',
    ley: 'company_type_name',
    width:250,
    scopedSlots: { customRender: 'company_type_name' },
  },
  {
    title: 'Acciones',
    dataIndex: 'actions',
    key: 'actions',
    scopedSlots: { customRender: 'actions' },
    width:100
  },
];

export default {
  components: {
    ModalConfirmation,
    CompaniesTableHeader
  },
  data() {
    return {
      data: [],
      columns,
      searchTerm: null,
      selectedRowKeys: [],
      loading: false,
      current: null,
      page: 1,
      pageSize: 100,
      showModal: false,
      count: 0,
      search: null,
      pageSizeOptions: ['50', '100', '150', '200', '250']
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  
  created(){
    if (this.$route.query.search) {
      this.searchTerm = this.$route.query.search;
      this.search = this.searchTerm;
    } 
    if(this.$route.query.page){
        this.page = parseInt(this.$route.query.page);
    } else {
        this.page = 1;
    }
        if (this.$route.query.pageSize) {
      this.pageSize = parseInt(this.$route.query.pageSize);
    } else {
      this.pageSize = 100;
    }
    this.setFilterInData()
  },
  watch: {
      'page': function(newVal, oldVal){
        if(newVal == this.$route.query.page){
          return;
        } else {
          this.$router.replace({query: { page:this.page, search: this.searchTerm}});
        }
      },
      pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
      return;
      } else {
        this.$router.replace({ query: { page: this.page, pageSize:this.pageSize } });
      }
    },
      '$route.query.page': function(){
         this.setFilterInData()
      }
  },
  methods: {
    onSearchTerm(value) {
      this.showToast('info', 'Buscando resultados...');
      this.searchTerm = value?.trim();
      this.search = this.searchTerm;
      if(1 == this.$route.query.page || !this.$route.query.page){
          this.$router.replace({query: { page:this.page, search: this.searchTerm}});
          this.setFilterInData()
        } 
      this.page = 1;
    },
    async setFilterInData() {
        let query = `?page=${this.page}&page_size=${this.pageSize}`;
        if (this.searchTerm) query += `&search=${this.searchTerm}`;
        
        this.loading = true;
        let {error, data} = await this.$api.readCompany(query);
        this.loading = false;

        if (error) {
           this.data = [];
          this.showToast('error', 'Error listando entidades.');
        }
        if (data) {
          this.data = data.results;
          this.count = data.count;
        }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async onSwitchChange(checked, record){
      this.loading = true;
      this.showToast('info', 'Procesando cambio de estado...');
      let {error, data} = await this.$api.changeStateCompany({is_active: checked}, record.id);
      this.loading = false;

      if (error) this.showToast('error', 'Error inacitvando entidades.');

      if (data) {
        this.showToast('success', 'Actualización de estado éxitosa.');
        this.setFilterInData();
      }
    },
    toggleModal(value){
      this.showModal = value?.toggle;
      if (value?.reload) {
        this.setFilterInData();
      }
    },
    onShowSizeChange(page, page_size){
    this.page = page
    this.pageSize = page_size
    this.start();
    },
    showToast(result = 'info', msg) {
      this.$notification[result]({
                message: 'Resultado',
                description: msg,
                onClick: () => {},
            });
    }
  },
};
</script>
<style>
.companies .ant-table-thead > tr > th {
  font-weight: bold;
  text-align: center;
  color:#6b7280;
}</style>